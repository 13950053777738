const ownLink = "https://opimisil.com/ZTKprRX4";

const getVisible = (el) => {
  const rect = el.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const vertInView =
    rect.top <= windowHeight - 50 && rect.top + rect.height >= 0;

  return vertInView;
};

const handleAnim = (collection, t = 0.4, d = 4) => {
  let order = 0;
  collection.forEach((item) => {
    if (item.classList.contains("anim_show")) return;

    if (!getVisible(item)) return;

    item.classList.add("anim_show");
    item.style.setProperty("--t", `${t}s`);
    item.style.setProperty("--d", `${(order * t) / d}s`);
    order++;
  });
};

const animColl = document.querySelectorAll(".anim");
const animFunc = () => {
  if (!animColl) return;
  handleAnim(animColl);
};
animFunc();

const handleSpecPromo = (block) => {
  const wrapper = document.querySelector(".footer__special");

  const changeHeight = () => {
    const height = block.clientHeight;

    if (height === wrapper.clientHeight) return;

    wrapper.style.height = `${height}px`;
  };

  changeHeight();

  window.addEventListener("resize", () => {
    changeHeight();
  });

  const onVisible = () => {
    const isVisible = getVisible(wrapper);

    if (!isVisible) {
      block.classList.add("specialPromo_sticky");
      return;
    }

    if (!block.classList.contains("specialPromo_sticky")) return;

    block.style.width = `${wrapper.clientWidth}px`;
    setTimeout(() => {
      block.classList.remove("specialPromo_sticky");
      block.removeAttribute("style");
    }, 0);
  };

  window.addEventListener("scroll", () => {
    onVisible();
  });

  onVisible();
};

const onAnimPromo = (block) => {
  const icons = block.querySelectorAll(".specialPromo__icon");

  icons.forEach((icon, i) => {
    const maxScale = 120;
    const minScale = 90;
    let step = 0.7;
    let progress = 100;
    function animIncr() {
      progress += step;
      icon.style.scale = progress / 100;

      if (progress >= maxScale) return requestAnimationFrame(animDecr);

      requestAnimationFrame(animIncr);
    }

    function animDecr() {
      progress -= step;
      icon.style.scale = progress / 100;

      if (progress <= minScale) return requestAnimationFrame(animIncr);

      requestAnimationFrame(animDecr);
    }

    setTimeout(() => {
      requestAnimationFrame(animIncr);
    }, i * 500);
  });
};

const playTrailer = (sec) => {
  const video = sec.querySelector(".trailer__video");
  const cover = sec.querySelector(".trailer__cover");
  const btn = sec.querySelector(".trailer__cover-btn");

  btn.onclick = () => {
    video.classList.remove("trailer__video_off");

    const youtube = btn.dataset.youtube;
    const iframe = document.createElement("iframe");
    iframe.src = youtube;
    iframe.autoplay = true;
    video.appendChild(iframe);

    setTimeout(() => {
      cover.remove();
    }, 300);
  };
};

document.addEventListener("DOMContentLoaded", () => {
  const accordionColl = document.querySelectorAll(".accord");
  accordionColl.forEach((item) => {
    const accordion = item;

    const closeAccord = (accord, wrapper, answer) => {
      wrapper.style.height = `${answer.clientHeight}px`;

      setTimeout(() => {
        wrapper.style.height = "0px";
        accord.classList.remove("accord_active");
      }, 0);

      setTimeout(() => {
        wrapper.removeAttribute("style");
        accord.classList.remove("accord_active");
      }, 400);
    };

    accordion.onclick = () => {
      const wrapper = accordion.querySelector(".accord__wrapper");
      const answer = accordion.querySelector(".accord__content");

      if (accordion.classList.contains("accord_active")) {
        closeAccord(accordion, wrapper, answer);
        return;
      }

      const currActive = document.querySelector(".accord_active");
      if (currActive) {
        const activeWrapper = currActive.querySelector(".accord__wrapper");
        const activeAnswer = currActive.querySelector(".accord__content");
        closeAccord(currActive, activeWrapper, activeAnswer);
      }

      wrapper.style.height = `${answer.clientHeight}px`;

      accordion.classList.add("accord_active");
      setTimeout(() => {
        wrapper.removeAttribute("style");
      }, 400);
    };
  });

  const specPromo = document.querySelector(".specialPromo");
  if (specPromo) {
    handleSpecPromo(specPromo);
  }

  const years = document.querySelectorAll(".year");
  if (years) {
    const date = new Date();
    const yy = date.getFullYear();

    years.forEach((year) => {
      year.textContent = yy;
    });
  }

  const promo = document.querySelector(".specialPromo");
  if (promo) {
    onAnimPromo(promo);
  }

  const trailerSec = document.querySelector(".trailer");
  if (trailerSec) {
    playTrailer(trailerSec);
  }

  window.onresize = () => {
    animFunc();
  };

  window.onscroll = () => {
    animFunc();
  };

  const imgColl = document.querySelectorAll("img");
  if (imgColl) {
    imgColl.forEach((img) => {
      if (
        img.closest(".logo") ||
        img.closest(".trailer__cover") ||
        img.closest(".footer__gambling-img") ||
        img.closest(".footer__trustpilot-img")
      )
        return;

      img.onclick = () => window.location.assign(ownLink);
    });
  }
});
